.root {
  height: 100vh;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  box-shadow: 0px 7px 9px #888888;
}

.logo {
  width: 25vw;
}

.text {
  width: 30vw;
}

.title {
  font-family: 'Dancing Script';
  white-space: nowrap;
  font-weight: bold;
  font-size: calc(16px + 4vw);
  color: #4690d3;
}

.subtitle {
  white-space: nowrap;
  font-size: calc(8px + 1vw);
  color: #4690d3;
}

.menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
  justify-content: space-around;
  gap: 20px;
  padding: 40px;
}

.menuItem {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 40px;
  box-shadow: 0px 7px 9px #888888;
}

.scheduleItem {
  display: flex;
  height: 300px;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 40px;
  box-shadow: 0px 7px 9px #888888;
}

.statement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: 'Dancing Script';
  font-size: 24px;
  color: #282c34;
  padding: 1vh 10vw 1vh 10vw;
}

.statementLine {
  padding: 16px;
}

.textIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItemTitle {
  font-family: 'Dancing Script';
  font-size: 32px;
  text-align: center;
  color: #282c34;
  font-weight: bold;
}

.nextLesson {
  font-family: 'Dancing Script';
  font-size: 24px;
  text-align: center;
  color: #282c34;
}

.schedule {
  display: flex;
  justify-content: center;
  font-family: 'Dancing Script';
  color: #282c34;
  font-size: 20px;
  text-align: center;
}
