.root {
  font-size: x-small;
  display: flex;
  align-items: center;
  background-color: darkgray;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.contacts {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.contact {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.contactItem {
  padding-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
}

.socialMedia {
  display: flex;
  align-items: center;
}

.socialMediaItem {
  padding-left: 5px;
  padding-right: 5px;
}

.clickable {
  cursor: pointer;
  font-size: 100;
}

.clickable:hover {
  color: cyan;
}
